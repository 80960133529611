// extracted by mini-css-extract-plugin
export var component = "styles-module--component--1BIfg";
export var cover = "styles-module--cover--2KaJu";
export var bg = "styles-module--bg--3gaV3";
export var started = "styles-module--started--1AvFe";
export var button = "styles-module--button--2hlo9";
export var videoWrap = "styles-module--video-wrap--3L5Wz";
export var videoBg = "styles-module--video-bg--2MXib";
export var video = "styles-module--video--XwZtx";
export var image = "styles-module--image--1JI7l";
export var front = "styles-module--front--1cYGv";
export var mark = "styles-module--mark--2TVzF";
export var text = "styles-module--text--36sSU";
export var open = "styles-module--open--25UkT";
export var arrow = "styles-module--arrow--3qLmm";