import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import MarkdownWrap from 'components/MarkdownWrap'
import Inview from 'components/Inview'
import HeaderVersionWrap from 'components/HeaderVersionWrap'
import ParallaxWrapBanner from 'components/ParallaxWrapBanner'
import Video from 'components/Video'
import ImageWrap from 'components/ImageWrap'
import MarkSvg from 'assets/svg/vi/mark-white-gradient.svg'
import * as styles from './styles.module.scss'

const Block = ({ content, locale, layoutProps }) => {
  const {
    setIsHeaderHidden,
    isHomeBannerStarted,
    setIsHomeBannerStarted,
    isHomeBannerOpen,
    setIsHomeBannerOpen,
  } = layoutProps
  const { video, image, body } = content

  useEffect(() => {
    if (!isHomeBannerOpen) {
      setIsHomeBannerOpen(false)
      setIsHeaderHidden(true)
      setTimeout(() => setIsHomeBannerStarted(true), 1)
    }
  }, [])

  const open = () => {
    setIsHomeBannerOpen(true)
    setIsHeaderHidden(false)
  }
  const isHomeBannerStartedClass = isHomeBannerStarted ? styles.started : ''
  const isHomeBannerOpenClass = isHomeBannerOpen ? styles.open : ''

  const handleScrollDown = (e) => {
    const block = e.target.closest('.block')
    window.scrollTo({
      top: block.offsetHeight,
      behavior: 'smooth',
    })
  }

  const strings = {
    en: {
      enter: 'Enter',
    },
    zh: {
      enter: '进入',
    },
  }
  const tr = strings[locale]
  return (
    <Inview className={`block ${styles.component} ${isHomeBannerOpenClass} `}>
      <HeaderVersionWrap layoutProps={layoutProps}>
        <div className={`${styles.cover} fade-in ${isHomeBannerStartedClass}`}>
          <div className={styles.bg} />
          <div className={styles.button} onClick={() => open()}>
            <h5>{tr.enter}</h5>
          </div>
        </div>
        <div className={styles.videoWrap}>
          <div className={styles.videoBg} />
          <ParallaxWrapBanner>
            <div className={`${styles.video} fade-in`}>
              <Video content={video} />
            </div>
            <div className={`${styles.image} fade-in`}>
              <ImageWrap image={image} />
            </div>
          </ParallaxWrapBanner>
        </div>
        <div className={`${styles.front} fade-in ${isHomeBannerStartedClass}`}>
          <div className={styles.mark}>
            <MarkSvg />
          </div>
          <div className={`${styles.text} p-larger`}>
            <div>
              <MarkdownWrap body={body} />
            </div>
          </div>
          <div className={styles.button} onClick={handleScrollDown}>
            <div className={styles.arrow} />
          </div>
        </div>
      </HeaderVersionWrap>
    </Inview>
  )
}

export default Block
