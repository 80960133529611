import React from 'react'
import Social from 'components/Social'
import SocialSingle from 'components/SocialSingle'
import LineLink from 'components/LineLink'
import ImageWrap from 'components/ImageWrap'
import MarkdownWrap from 'components/MarkdownWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { pretitle, title, phone, email, body, image, body2 } = content
  return (
    <div className='block pad-v-standard first-add-v-pad'>
      <div className='container'>
        <Inview className={`${styles.title} fade-in up`}>
          {pretitle && <h5>{pretitle}</h5>}
          <h1>{title}</h1>
          <div className={`p-smaller ${styles.body}`}>
            <div className={styles.customTop}>
              <p className={styles.phone}>
                {phone}{' '}
                <span className={styles.socialItem}>
                  <SocialSingle version='dark' />
                </span>
              </p>
              <p className={styles.email}>
                <LineLink
                  content={{ to: email, text: email, linkType: 'email' }}
                />
              </p>
            </div>
            <MarkdownWrap body={body} />
          </div>
        </Inview>
        <Inview className={`grid-12 ${styles.image} fade-in up`}>
          <ImageWrap image={image} />
        </Inview>
        <Inview className={`${styles.text} fade-in up`}>
          <div className={`p-smaller ${styles.body}`}>
            <MarkdownWrap body={body2} />
          </div>
          <div className={styles.social}>
            <Social version='dark' />
          </div>
        </Inview>
      </div>
    </div>
  )
}

export default Block
