import React from 'react'
import BannerChinese from 'blocks/BannerChinese/template'
import BannerFull from 'blocks/BannerFull/template'
import BannerTextOnly from 'blocks/BannerTextOnly/template'
import BannerTextOnlySmaller from 'blocks/BannerTextOnlySmaller/template'
import BannerVideo from 'blocks/BannerVideo/template'
import BannerWrapped from 'blocks/BannerWrapped/template'
import ButtonWrap from 'blocks/ButtonWrap/template'
import CollectionsList from 'blocks/CollectionsList/template'
import Contact from 'blocks/Contact/template'
import DarkModeToggle from 'blocks/DarkModeToggle/template'
import DesignerGrid from 'blocks/DesignerGrid/template'
import ImageInner from 'blocks/ImageInner/template'
import ImagesUneven from 'blocks/ImagesUneven/template'
import PartnerGrid from 'blocks/PartnerGrid/template'
import PostGrid from 'blocks/PostGrid/template'
import PostList from 'blocks/PostList/template'
import ProductGridSelect from 'blocks/ProductGridSelect/template'
import ProductSection from 'blocks/ProductSection/template'
import TextImage from 'blocks/TextImage/template'
import TextImageBg from 'blocks/TextImageBg/template'
import TextOnly from 'blocks/TextOnly/template'
import * as styles from './styles.module.scss'

const components = {
  bannerChinese: BannerChinese,
  bannerFull: BannerFull,
  bannerTextOnly: BannerTextOnly,
  bannerTextOnlySmaller: BannerTextOnlySmaller,
  bannerVideo: BannerVideo,
  bannerWrapped: BannerWrapped,
  buttonWrap: ButtonWrap,
  collectionsList: CollectionsList,
  contact: Contact,
  darkModeToggle: DarkModeToggle,
  designerGrid: DesignerGrid,
  imageInner: ImageInner,
  imagesUneven: ImagesUneven,
  partnerGrid: PartnerGrid,
  postGrid: PostGrid,
  postList: PostList,
  productGridSelect: ProductGridSelect,
  productSection: ProductSection,
  textImage: TextImage,
  textImageBg: TextImageBg,
  textOnly: TextOnly,
}

const ContentType = ({ content, locale, layoutProps }) => {
  if (!locale) locale = 'en'

  let { main } = content

  return (
    <div className={styles.page}>
      {main?.map((block, i) => {
        if (components[block.type]) {
          return React.createElement(components[block.type], {
            content: block,
            locale,
            layoutProps,
            key: i,
          })
        }
      })}
    </div>
  )
}

export default ContentType
