import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ProductGrid from 'components/ProductGrid'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ data, content, locale }) => {
  const { productDiv } = content

  const { productDivs, products, productCats, collections } = data
  const productDivsLocale = productDivs.nodes.map(
    (productDiv) => productDiv.frontmatter[locale]
  )
  const productsLocale = products.nodes.map(
    (product) => product.frontmatter[locale]
  )
  const productCatsLocale = productCats.nodes.map(
    (productCat) => productCat.frontmatter[locale]
  )
  // get only productCats from this productDiv
  const productCatsLocaleDiv = productCatsLocale.filter(
    (productCat) => productCat.productDiv === productDiv
  )
  // sort by order
  const productCatsLocaleDivSorted = productCatsLocaleDiv.sort((a, b) =>
    a.order > b.order ? 1 : -1
  )

  const collectionsLocale = collections.nodes.map(
    (collection) => collection.frontmatter[locale]
  )
  // get only collections from this productDiv
  const collectionsLocaleDiv = collectionsLocale.filter(
    (collection) => collection.productDiv === productDiv
  )
  // sort by order
  const collectionsLocaleDivSorted = collectionsLocaleDiv.sort((a, b) =>
    a.order > b.order ? 1 : -1
  )

  // get only products from those collections
  let productsLocaleDiv = productsLocale.filter((product) => {
    return collectionsLocaleDiv.find((collection) => {
      return collection.slug === product.collection
    })
  })

  const [activeProducts, setActiveProducts] = useState(productsLocaleDiv)
  const [activeCollectionSlugs, setActiveCollectionSlugs] = useState([])
  const [activeProductCatSlugs, setActiveProductCatSlugs] = useState([])

  useEffect(() => {
    updateActiveProducts()
  }, [activeCollectionSlugs, activeProductCatSlugs])

  const updateActiveProducts = () => {
    let newState = productsLocaleDiv
    if (activeCollectionSlugs.length > 0) {
      newState = newState.filter((product) =>
        activeCollectionSlugs.includes(product.collection)
      )
    }
    if (activeProductCatSlugs.length > 0) {
      newState = newState.filter((product) =>
        activeProductCatSlugs.includes(product.productCat)
      )
    }
    setActiveProducts([])
    setTimeout(() => setActiveProducts(newState), 1)
  }

  const updateActiveCollectionSlugs = (slug) => {
    let newState = [...activeCollectionSlugs]
    if (newState.includes(slug)) {
      newState.splice(newState.indexOf(slug), 1)
    } else {
      newState.push(slug)
    }
    setActiveCollectionSlugs(newState)
  }
  const updateActiveProductCatSlugs = (slug) => {
    let newState = [...activeProductCatSlugs]
    if (newState.includes(slug)) {
      newState.splice(newState.indexOf(slug), 1)
    } else {
      newState.push(slug)
    }
    setActiveProductCatSlugs(newState)
  }

  const strings = {
    en: {
      collections: 'Collections',
      categories: 'Categories',
    },
    zh: {
      collections: '系列',
      categories: '类别',
    },
  }
  const tr = strings[locale]

  const bgClass = productDiv === 'teaware' ? styles.bgWhite : ''
  return (
    <div className={`pad-v-standard ${styles.component} ${bgClass}`}>
      <div className='container'>
        <TopBar
          activeProducts={activeProducts}
          productDivs={productDivsLocale}
          productDiv={productDiv}
          locale={locale}
        />
        <div className={styles.filtersGridWrap}>
          <div className={styles.filters}>
            <Filters
              title={tr.collections}
              items={collectionsLocaleDivSorted}
              activeSlugs={activeCollectionSlugs}
              updateFunction={updateActiveCollectionSlugs}
              locale={locale}
            />
            <Filters
              title={tr.categories}
              items={productCatsLocaleDivSorted}
              activeSlugs={activeProductCatSlugs}
              updateFunction={updateActiveProductCatSlugs}
              locale={locale}
            />
          </div>
          <ProductGrid
            products={activeProducts}
            productDivSlug={productDiv}
            collections={collectionsLocaleDiv}
            locale={locale}
          />
        </div>
      </div>
    </div>
  )
}

const TopBarSimple = ({ productDivs, productDiv, locale }) => {
  const thisProductDiv = productDivs.find((item) => item.slug === productDiv)
  const otherProductDivs = productDivs.filter(
    (item) => item.slug !== productDiv
  )

  const strings = {
    en: {
      products: 'Products',
    },
    zh: {
      products: '产品',
    },
  }
  const tr = strings[locale]
  return (
    <Inview className={`fade-in up ${styles.topBar}`}>
      <h5>
        {tr.products}
        <span className={styles.divider} />
        {thisProductDiv.title}
      </h5>
    </Inview>
  )
}

const TopBar = ({ productDivs, productDiv, activeProducts, locale }) => {
  const thisProductDiv = productDivs.find((item) => item.slug === productDiv)
  const otherProductDivs = productDivs.filter(
    (item) => item.slug !== productDiv
  )

  const strings = {
    en: {
      result: 'result',
      results: 'results',
    },
    zh: {
      result: '*result',
      results: '*results',
    },
  }
  const tr = strings[locale]
  const resultText = activeProducts.length === 1 ? tr.result : tr.results
  return (
    <Inview className={`fade-in up ${styles.topBar}`}>
      <div className={styles.options}>
        <h5 className={styles.current}>{thisProductDiv.title}</h5>
        <div className={styles.links}>
          {otherProductDivs.map((item, i) => (
            <LinkWrap key={i} to={`/${item.slug}`}>
              <h5 key={i}>{item.title}</h5>
            </LinkWrap>
          ))}
        </div>
      </div>
      {/* <div className={styles.results}>
        <h5>
          {activeProducts.length} {resultText}
        </h5>
      </div> */}
    </Inview>
  )
}

const Filters = ({ title, items, activeSlugs, updateFunction, locale }) => {
  const lanClass = locale === 'en' ? styles.lanEn : styles.lanZh
  return (
    <Inview className={`fade-in up ${styles.filter}`}>
      <h6>{title}</h6>
      <div className={`${styles.options} ${lanClass}`}>
        {items.map((item, i) => (
          <FilterItem
            item={item}
            isActive={activeSlugs.includes(item.slug)}
            updateFunction={updateFunction}
            key={i}
          />
        ))}
      </div>
    </Inview>
  )
}

const FilterItem = ({ item, isActive, updateFunction }) => {
  const isActiveClass = isActive ? styles.isActive : ''
  return (
    <div
      className={`${styles.filterItem} ${isActiveClass}`}
      onClick={() => updateFunction(item.slug)}
    >
      <div className={styles.box} />
      <p>{item.title}</p>
    </div>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          productDivs: allMarkdownRemark(
            filter: {
              frontmatter: { en: { templateKey: { eq: "productDiv" } } }
            }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                  titleShorter
                }
                zh {
                  slug
                  title
                  titleShorter
                }
              }
            }
          }
          products: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "product" } } } }
          ) {
            nodes {
              frontmatter {
                en {
                  productCat
                  collection
                  slug
                  title
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                zh {
                  productCat
                  collection
                  slug
                  title
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
          productCats: allMarkdownRemark(
            filter: {
              frontmatter: { en: { templateKey: { eq: "productCat" } } }
            }
          ) {
            nodes {
              frontmatter {
                en {
                  order
                  slug
                  title
                  productDiv
                }
                zh {
                  order
                  slug
                  title
                  productDiv
                }
              }
            }
          }
          collections: allMarkdownRemark(
            filter: {
              frontmatter: { en: { templateKey: { eq: "collection" } } }
            }
          ) {
            nodes {
              frontmatter {
                en {
                  order
                  slug
                  title
                  productDiv
                }
                zh {
                  order
                  slug
                  title
                  productDiv
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block data={data} content={content} locale={locale} />}
    />
  )
}
