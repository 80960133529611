import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'
import ButtonLink from 'components/ButtonLink'

const Block = ({ data, content, locale }) => {
  const { title, isExcerpt } = content
  let designers = data?.designers?.nodes
  if (isExcerpt) {
    designers = designers.slice(0, 3)
  }
  const designersLocale = designers.map((item) => item.frontmatter[locale])
  const strings = {
    en: {
      viewAll: 'View All',
    },
    zh: {
      viewAll: '查看全部',
    },
  }
  const tr = strings[locale]
  const buttonLinkContent = {
    text: tr.viewAll,
    to: '/about',
    linkType: 'page',
  }
  return (
    <div className='pad-v-standard'>
      <div className='container'>
        <Inview className={`${styles.title} fade-in up`}>
          <h2>{title}</h2>
        </Inview>
        <Inview className={`${styles.items} grid-12`}>
          <div className={styles.gridInner}>
            {designersLocale?.map((item, i) => {
              return <Item content={item} key={i} i={i} />
            })}
          </div>
        </Inview>
        {isExcerpt && (
          <Inview className={`${styles.buttonWrap} fade-in up`}>
            <ButtonLink content={buttonLinkContent} />
          </Inview>
        )}
      </div>
    </div>
  )
}

const Item = ({ content, i }) => {
  const { slug, name, image } = content || {}
  const link = `/designers/${slug}`
  return (
    <div className={`${styles.item} fade-in up stagger-${i * 50 + 500}`}>
      <LinkWrap to={link}>
        <ImageWrap image={image} aspectRatio={5 / 6} />
      </LinkWrap>
      <div className={styles.text}>
        <LinkWrap to={link}>
          <h4 className='smaller'>{name}</h4>
        </LinkWrap>
      </div>
    </div>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          designers: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "designer" } } } }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  name
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                zh {
                  slug
                  name
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block data={data} content={content} locale={locale} />}
    />
  )
}
