import React from 'react'
import Seo from 'components/Seo'
import PageTemplate from './template'

const ContentType = (props) => {
  const { pageContext, layoutProps } = props
  const { id, pages, locale } = pageContext
  const page = pages.find((page) => page.id === id)
  const content = page.frontmatter[locale]
  return (
    <>
      <Seo content={content} />
      <PageTemplate
        content={content}
        locale={locale}
        layoutProps={layoutProps}
      />
    </>
  )
}

export default ContentType
