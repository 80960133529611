import React from 'react'
import ImageWrap from 'components/ImageWrap'
import HeaderVersionWrap from 'components/HeaderVersionWrap'
import ParallaxWrapBanner from 'components/ParallaxWrapBanner'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content, layoutProps }) => {
  const { title, image } = content
  return (
    <Inview className={`${styles.component} block fade-in`}>
      <HeaderVersionWrap layoutProps={layoutProps}>
        <div className={styles.image}>
          <ParallaxWrapBanner>
            <ImageWrap image={image} />
          </ParallaxWrapBanner>
        </div>
        <div className={`${styles.text} container`}>
          <h1>{title}</h1>
        </div>
      </HeaderVersionWrap>
    </Inview>
  )
}

export default Block
