import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ButtonLink from 'components/ButtonLink'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'
import { formatDate } from 'js/utils'

const Block = ({ data, content, locale }) => {
  const { title, isExcerpt } = content
  let posts = data?.posts?.nodes
  if (isExcerpt) {
    posts = posts.slice(0, 3)
  }
  const postsLocale = posts.map((item) => item.frontmatter[locale])
  const strings = {
    en: {
      viewAll: 'View All',
    },
    zh: {
      viewAll: '查看全部',
    },
  }
  const tr = strings[locale]
  const buttonLinkContent = {
    text: tr.viewAll,
    to: '/news',
    linkType: 'page',
  }
  return (
    <div className='pad-v-standard'>
      <div className='container'>
        <Inview className={`${styles.title} fade-in up`}>
          <h2>{title}</h2>
        </Inview>
        <Inview className={`${styles.items}`}>
          {postsLocale?.map((item, i) => (
            <Item content={item} key={i} i={i} locale={locale} />
          ))}
        </Inview>
        {isExcerpt && (
          <Inview className={`${styles.buttonWrap} fade-in up`}>
            <ButtonLink content={buttonLinkContent} />
          </Inview>
        )}
      </div>
    </div>
  )
}

const Item = ({ content, i, locale }) => {
  const { date, title, linkExternal, image } = content || {}
  const showDate = formatDate(new Date(date), locale)
  return (
    <Inview className={`${styles.item} fade-in up stagger-${i * 50 + 500}`}>
      <a href={linkExternal} target='_blank'>
        <div className={styles.text}>
          <h5>{showDate}</h5>
          <h4>{title}</h4>
        </div>
        <div className={styles.imageWrap}>
          <div className={styles.image}>
            <ImageWrap image={image} aspectRatio={1.5} />
          </div>
        </div>
      </a>
    </Inview>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          posts: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "post" } } } }
            sort: { fields: frontmatter___en___date, order: DESC }
          ) {
            nodes {
              frontmatter {
                en {
                  date
                  title
                  linkExternal
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                zh {
                  date
                  title
                  linkExternal
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block data={data} content={content} locale={locale} />}
    />
  )
}
