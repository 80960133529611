import React from 'react'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { pretitle, title } = content
  return (
    <div className='block pad-v-standard'>
      <Inview className='container fade-in up'>
        <div className={styles.text}>
          {pretitle && <h5>{pretitle}</h5>}
          <h1>{title}</h1>
        </div>
      </Inview>
    </div>
  )
}

export default Block
