import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ data, content, locale }) => {
  const { title } = content
  const partners = data?.partners?.nodes
  const partnersLocale = partners.map((item) => item.frontmatter[locale])
  return (
    <div className='pad-v-standard'>
      <div className='container'>
        <Inview className={`${styles.title} fade-in up`}>
          <h1>{title}</h1>
        </Inview>
        <Inview className={`${styles.grid} fade-in up grid-12`}>
          {partnersLocale?.map((item, i) => (
            <Logo content={item} key={i} i={i} />
          ))}
        </Inview>
      </div>
    </div>
  )
}

const Logo = ({ content, i }) => {
  const { logo, url } = content || {}
  if (url) {
    return (
      <a
        href={url}
        target='_blank'
        className={`${styles.logo} fade-in up stagger-${i * 50 + 500}`}
      >
        <ImageWrap image={logo} aspectRatio={1} />
      </a>
    )
  } else {
    return (
      <div className={`${styles.logo} fade-in up stagger-${i * 50 + 500}`}>
        <ImageWrap image={logo} aspectRatio={1} />
      </div>
    )
  }
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          partners: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "partner" } } } }
          ) {
            nodes {
              frontmatter {
                en {
                  title
                  logo {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  url
                }
                zh {
                  title
                  logo {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  url
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block data={data} content={content} locale={locale} />}
    />
  )
}
