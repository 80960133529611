import React from 'react'
import ProductGrid from 'components/ProductGrid'
import CenteredPaddedSlideshow from 'components/Slideshows/CenteredPaddedSlideshow'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'
import { getProductDiv } from 'js/utils'

const Block = ({ content, locale }) => {
  const { productDiv, products } = content
  const productsInner = products.map((product) => product.data)

  const slideshowItems = productsInner.map((item) => {
    const productDivLocale = getProductDiv(item, locale)
    return {
      pretitle: item.collection?.data.frontmatter[locale].title,
      title: item.title,
      image: item.image,
      link: `/${productDivLocale.slug}/${item.slug}`,
    }
  })

  const strings = {
    en: {
      viewAll: 'View All',
    },
    zh: {
      viewAll: '查看全部',
    },
  }
  const tr = strings[locale]
  const buttonLinkContent = {
    text: tr.viewAll,
    to: `/${productDiv}`,
    linkType: 'page',
  }
  const bgClass = productDiv === 'teaware' ? 'bg-white' : ''
  return (
    <div className={`${bgClass} pad-v-standard`}>
      <div className={styles.grid}>
        <div className='container'>
          <ProductGrid products={productsInner} locale={locale} columns={4} />
          <Inview
            className={`${styles.buttonWrap} button-wrap fade-in up stagger-1000`}
          >
            <ButtonLink content={buttonLinkContent} />
          </Inview>
        </div>
      </div>
      <div className={styles.slideshow}>
        <CenteredPaddedSlideshow
          items={slideshowItems}
          id={Math.floor(Math.random() * 1000000)}
          hasButton={true}
        />
        <div className='container'>
          <Inview
            className={`${styles.buttonWrap} button-wrap fade-in up stagger-1000`}
          >
            <ButtonLink content={buttonLinkContent} />
          </Inview>
        </div>
      </div>
    </div>
  )
}

export default Block
