import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ImagesUneven from 'blocks/ImagesUneven/template'

const Block = ({ data, content, locale }) => {
  const { productDiv } = content
  const collections = data?.collections?.nodes
  const collectionsLocale = collections.map((item) => item.frontmatter[locale])
  const productDivs = data?.productDivs?.nodes
  const productDivsLocale = productDivs.map((item) => item.frontmatter[locale])
  const productDivCollectionsLocale = collectionsLocale.filter(
    (item) => item.productDiv === productDiv
  )

  let isReversed = true
  const imagesUnevenContent = productDivCollectionsLocale.map((item) => {
    if (item.images.secondary) {
      isReversed = !isReversed
    }
    const thisProductDiv = productDivsLocale.find(
      (productDiv) => productDiv.slug === item.productDiv
    )
    return {
      pageLink: `/collections/${item.slug}`,
      pretitle: thisProductDiv.titleShorter,
      title: item.title,
      images: item.images,
      isReversed,
    }
  })
  const bgClass = productDiv === 'teaware' ? 'bg-white' : ''

  return (
    <>
      {imagesUnevenContent?.map((block, i) => {
        block.bgClass = bgClass
        return <ImagesUneven content={block} key={i} />
      })}
    </>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          collections: allMarkdownRemark(
            filter: {
              frontmatter: { en: { templateKey: { eq: "collection" } } }
            }
          ) {
            nodes {
              frontmatter {
                en {
                  productDiv
                  slug
                  title
                  images {
                    main {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    secondary {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                zh {
                  productDiv
                  slug
                  title
                  images {
                    main {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    secondary {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
          productDivs: allMarkdownRemark(
            filter: {
              frontmatter: { en: { templateKey: { eq: "productDiv" } } }
            }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                  titleShorter
                }
                zh {
                  slug
                  title
                  titleShorter
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block data={data} content={content} locale={locale} />}
    />
  )
}
