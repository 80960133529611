import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'
import { formatDate } from 'js/utils'

const Block = ({ data, content, locale }) => {
  const { title, isExcerpt } = content
  const posts = data?.posts?.nodes
  const postsLocale = posts.map((item) => item.frontmatter[locale])
  return (
    <div className={`pad-v-standard ${styles.component}`}>
      <div className='container'>
        {title && (
          <Inview className={`${styles.title} fade-in up`}>
            <h1>{title}</h1>
          </Inview>
        )}
        <Inview className={`${styles.items} grid-12`}>
          <div className={styles.gridInner}>
            {postsLocale?.map((item, i) => {
              return <Item content={item} key={i} i={i} locale={locale} />
            })}
          </div>
        </Inview>
        {isExcerpt && (
          <Inview className={`${styles.buttonWrap} fade-in up`}>
            <ButtonLink content={buttonLinkContent} />
          </Inview>
        )}
      </div>
    </div>
  )
}

const Item = ({ content, i, locale }) => {
  const { date, title, linkExternal, image } = content || {}
  const showDate = formatDate(new Date(date), locale)
  return (
    <Inview className={`${styles.item} fade-in up stagger-${i * 50 + 500}`}>
      <a href={linkExternal} target='_blank'>
        <ImageWrap image={image} aspectRatio={1.5} />
        <div className={styles.text}>
          <h5>{showDate}</h5>
          <h4>{title}</h4>
        </div>
      </a>
    </Inview>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          posts: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "post" } } } }
            sort: { fields: frontmatter___en___date, order: DESC }
          ) {
            nodes {
              frontmatter {
                en {
                  date
                  title
                  linkExternal
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                zh {
                  date
                  title
                  linkExternal
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block data={data} content={content} locale={locale} />}
    />
  )
}
