import React from 'react'
import ImageWrap from 'components/ImageWrap'
import MarkdownWrap from 'components/MarkdownWrap'
import HeaderVersionWrap from 'components/HeaderVersionWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content, layoutProps }) => {
  const { title, body, image } = content || {}
  return (
    <Inview className='block fade-in up bg-green bg-dark'>
      <HeaderVersionWrap layoutProps={layoutProps}>
        <div className=' pad-v-standard'>
          <div className={`container grid-12 ${styles.grid}`}>
            <div className={styles.text}>
              <h2>{title}</h2>
              <MarkdownWrap body={body} />
            </div>
            <div className={styles.image}>
              <ImageWrap image={image} />
            </div>
          </div>
        </div>
      </HeaderVersionWrap>
    </Inview>
  )
}

export default Block
