import React from 'react'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const {
    pageLink,
    pretitle,
    title,
    images,
    bgClass = '',
    isReversed = '',
  } = content

  let layoutClass = styles.single
  if (images?.secondary) layoutClass = styles.double
  const isReversedClass = isReversed ? styles.isReversed : ''

  return (
    <div className={`pad-v-standard ${bgClass}`}>
      <Inview className='container fade-in up'>
        <LinkWrap
          to={pageLink}
          className={`grid-12 ${styles.inner} ${layoutClass} ${isReversedClass}`}
        >
          <div className={styles.main}>
            {images.main && <ImageWrap image={images.main} />}
            {(pretitle || title) && (
              <div className={styles.textWrap}>
                {pretitle && <h5>{pretitle}</h5>}
                <h4>{title}</h4>
              </div>
            )}
          </div>
          {images.secondary && (
            <div className={styles.secondary}>
              <ImageWrap image={images.secondary} />
              {(pretitle || title) && (
                <div className={styles.textWrap}>
                  <h4>&nbsp;</h4>
                </div>
              )}
            </div>
          )}
        </LinkWrap>
        {(pretitle || title) && (
          <LinkWrap to={pageLink} className={styles.mobileTitle}>
            {pretitle && <h5>{pretitle}</h5>}
            <h3>{title}</h3>
          </LinkWrap>
        )}
      </Inview>
    </div>
  )
}

export default Block
