import React from 'react'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { productDiv, title, svg } = content
  const bgClass = productDiv === 'teaware' ? 'bg-white' : ''
  return (
    <div
      className={`block pad-v-standard no-collapse top-only ${styles.component} ${bgClass} `}
    >
      <Inview className='container fade-in up'>
        <div className={styles.wrap}>
          <div className={styles.text}>
            <h1>{title}</h1>
          </div>
          <div className={styles.image}>
            <img src={svg.publicURL} />
          </div>
        </div>
      </Inview>
    </div>
  )
}

export default Block
